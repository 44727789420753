import {
  CareerSiteFiltersOption,
  CategoryOptions,
  RadiusOptions,
} from 'api-clients/monolith';
import { createContext, Dispatch, SetStateAction } from 'react';

export const sortOptions = [
  { key: 'distance', label: 'Distance' },
  { key: 'title', label: 'Job Title' },
  { key: 'date_posted', label: 'Date Posted' },
] as const;

export const compensationTypeOptions = [
  { key: 'any', label: 'Any' },
  { key: 'hour', label: 'Per hour' },
  { key: 'day', label: 'Per day' },
  { key: 'month', label: 'Per month' },
  { key: 'year', label: 'Per year' },
] as const;

export type SortFilter = (typeof sortOptions)[number];
export type CompensationFilter = (typeof compensationTypeOptions)[number];

export interface ActiveFilters {
  compensationType: CompensationFilter;
  sortBy: SortFilter;
  radius: RadiusOptions;
  category: CategoryOptions;
  department: CareerSiteFiltersOption;
  location: CareerSiteFiltersOption;
  position: CareerSiteFiltersOption;
}

export interface Segment {
  id: string | null;
  value: string | null;
}

export type AppContextData = {
  accountSlug: string;
  brandId: string;
  brandName: string;
  activeFilters: ActiveFilters;
  setActiveFilters: Dispatch<SetStateAction<ActiveFilters>>;
  location: Segment;
  setLocation: Dispatch<SetStateAction<Segment>>;
  position: Segment;
  setPosition: Dispatch<SetStateAction<Segment>>;
  categoryName: string | null;
  setCategoryName: Dispatch<SetStateAction<string | null>>;
  experienceLevelName: string | null;
  setExperienceLevelName: Dispatch<SetStateAction<string | null>>;
  setBrandDetails: (
    accountSlug: string,
    brandId: string,
    brandName: string,
  ) => void;
  placeId: string | null;
  setPlaceId: Dispatch<SetStateAction<string | null>>;
  jobSearchQuery: string | null;
  setJobSearchQuery: Dispatch<SetStateAction<string | null>>;
  isFetchingJobs: boolean;
  setIsFetchingJobs: Dispatch<SetStateAction<boolean>>;
  resetFilters: () => void;
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
  isMobile: boolean;
  isJobsFromCurrentLocation: boolean;
  setIsJobsFromCurrentLocation: Dispatch<SetStateAction<boolean>>;
  setDefaultFilterData: (data: ActiveFilters) => void;
  isCmsAccount: boolean;
};

export const defaultAppContext: AppContextData = {
  accountSlug: 'test_account',
  brandId: '123',
  brandName: 'abc',
  activeFilters: {
    compensationType: { key: 'any', label: 'Any' },
    sortBy: { key: 'distance', label: 'Distance' },
    radius: { key: '50mi', label: '50 miles' },
    category: { key: 'Any', label: 'Any' },
    department: { key: 'all', label: 'all' },
    location: { key: 'all', label: 'all' },
    position: { key: 'all', label: 'all' },
  },
  setActiveFilters: () => undefined,
  location: { id: null, value: null },
  setLocation: () => undefined,
  position: { id: null, value: null },
  setPosition: () => undefined,
  categoryName: null,
  setCategoryName: () => undefined,
  experienceLevelName: null,
  setExperienceLevelName: () => undefined,
  setBrandDetails: () => undefined,
  placeId: null,
  setPlaceId: () => undefined,
  jobSearchQuery: null,
  setJobSearchQuery: () => undefined,
  isFetchingJobs: false,
  setIsFetchingJobs: () => undefined,
  resetFilters: () => undefined,
  page: 1,
  setPage: () => undefined,
  isMobile: false,
  isJobsFromCurrentLocation: true,
  setIsJobsFromCurrentLocation: () => undefined,
  setDefaultFilterData: () => undefined,
  isCmsAccount: false,
};

export const AppContext = createContext<AppContextData>(defaultAppContext);
