import {
  CareerSiteFiltersOption,
  CareerSiteSettings,
  CategoryOptions,
  RadiusOptions,
} from 'api-clients/monolith';
import cx from 'classnames';
import { AppContext } from 'contexts/app-context';
import React, { useContext, useRef } from 'react';

import { HomeMain } from 'components/home-main';
import { HomeSearch } from 'components/home-search';

import { Banner } from './banner-image';

interface HomeProps {
  brandSetting: CareerSiteSettings;
  radiusOptions: RadiusOptions[];
  categoryOptions: CategoryOptions[];
  departmentOptions: CareerSiteFiltersOption[];
  locationOptions: CareerSiteFiltersOption[];
  positionOptions: CareerSiteFiltersOption[];
}

export function Home({
  brandSetting,
  radiusOptions,
  categoryOptions,
  departmentOptions,
  locationOptions,
  positionOptions,
}: HomeProps) {
  const targetRef = useRef<HTMLDivElement>(null);
  const { isCmsAccount } = useContext(AppContext);

  const scrollToJobs = () => {
    if (targetRef.current) {
      const targetPosition = targetRef.current.offsetTop;

      // Scroll to the target element with a 64px offset to deduct the menubar height
      window.scrollTo({
        top: targetPosition - 64,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="flex flex-col">
      {isCmsAccount && <Banner />}
      <div className="w-full">
        <HomeSearch scrollToJobs={scrollToJobs} brandSetting={brandSetting} />
      </div>
      <div ref={targetRef}></div>
      <div
        className={cx('container flex flex-col gap-6 pt-10 pb-20', {
          '!pt-0': isCmsAccount,
        })}
      >
        <HomeMain
          scrollToJobs={scrollToJobs}
          brandSetting={brandSetting}
          radiusOptions={radiusOptions}
          categoryOptions={categoryOptions}
          departmentOptions={departmentOptions}
          locationOptions={locationOptions}
          positionOptions={positionOptions}
        />
      </div>
    </div>
  );
}

Home.Skeleton = function HomeSkeleton() {
  const { isCmsAccount } = useContext(AppContext);

  return (
    <div className="flex flex-col">
      {isCmsAccount && <Banner.Skeleton />}
      <div className="h-[346px] w-full">
        <HomeSearch.Skeleton />
      </div>

      <div className="container flex flex-col gap-8 pt-10 pb-20">
        <HomeMain.Skeleton />
      </div>
    </div>
  );
};
