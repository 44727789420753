import {
  CareerSiteFiltersOption,
  CategoryOptions,
  RadiusOptions,
} from 'api-clients/monolith';
import { AppContext } from 'contexts/app-context';
import React, { useContext } from 'react';

import { useCategories } from 'hooks/useCategories';

import { Card } from './ui/card';
import { HorizontalScroll } from './ui/horizontal-scroll';

export function JobCategoryCardsSkeleton() {
  return (
    <div className="flex flex-row overflow-x-scroll no-scrollbar md:overflow-x-hidden md:grid md:grid-cols-4 gap-3">
      {Array.from({ length: 8 }, (_, i) => (
        <Card.Skeleton key={`skeleton-${i}`} />
      ))}
    </div>
  );
}

interface JobCategoryCardsProps {
  scrollToJobs: () => void;
  categoryOptions: CategoryOptions[];
  radiusOptions: RadiusOptions[];
  departmentOptions: CareerSiteFiltersOption[];
  locationOptions: CareerSiteFiltersOption[];
  positionOptions: CareerSiteFiltersOption[];
}
export function JobCategoryCards({
  scrollToJobs,
  categoryOptions,
  radiusOptions,
  departmentOptions,
  locationOptions,
  positionOptions,
}: JobCategoryCardsProps) {
  const {
    accountSlug,
    brandId,
    isMobile,
    setDefaultFilterData,
    activeFilters,
  } = useContext(AppContext);

  const { data: categories, isFetching: isCategoriesFetching } = useCategories(
    accountSlug,
    brandId,
  );

  if (isCategoriesFetching) {
    return <JobCategoryCardsSkeleton />;
  }

  const handleClick = (name: string) => {
    scrollToJobs();
    const categoryOption = categoryOptions.find(
      option => option.label === name,
    );

    setDefaultFilterData({
      ...activeFilters,
      radius: radiusOptions[4],
      category: categoryOption ?? { key: 'Other', label: 'Other' },
      department: departmentOptions[0],
      location: locationOptions[0],
      position: positionOptions[0],
    });
  };

  return (
    <>
      {categories.length > 0 && (
        <>
          {isMobile ? (
            <div className="relative">
              <HorizontalScroll
                categories={categories}
                handleClick={handleClick}
              />
            </div>
          ) : (
            <div className="flex flex-row overflow-x-scroll no-scrollbar md:overflow-x-hidden md:grid md:grid-cols-4 gap-3">
              {categories.map(category => (
                <Card
                  key={category.name}
                  title={category.name}
                  count={category.count}
                  handleClick={handleClick}
                />
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
}
