import React from 'react';

export const ChatIcon = ({ ...props }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16 9.25033C16 6.35065 13.3135 4 10 4C6.6865 4 4 6.35065 4 9.25033C4 12.15 6.6865 14.5007 10 14.5007C10.4493 14.5007 10.8857 14.4542 11.3073 14.3716L13.9323 15.9467C13.9915 15.982 14.0583 16 14.125 16C14.1888 16 14.2525 15.9842 14.3095 15.9512C14.4272 15.8845 14.5 15.76 14.5 15.625V12.714C15.4307 11.7892 16 10.5794 16 9.25033Z"
      className="bg-primary fill-current"
    />
  </svg>
);
