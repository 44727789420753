import React from 'react';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'types';

import { Skeleton } from 'components/ui/skeleton';

export interface DesktopNavProps {
  logoUrl: string;
}

export function DesktopNav({ logoUrl }: DesktopNavProps) {
  const { accountSlug, brandId } = useParams<RouteParams>();

  return (
    <div className="flex items-center">
      <a href={`/${accountSlug}${brandId ? `/${brandId}` : ''}`}>
        <img src={logoUrl} alt="brand logo" className="h-14" />
      </a>
    </div>
  );
}

DesktopNav.Skeleton = function DesktopNavSkeleton() {
  return (
    <div className="flex items-center">
      <Skeleton className="h-10 w-[150px]" />
    </div>
  );
};
