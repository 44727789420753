import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';

import { Main } from './components/main';

export const Root = (): JSX.Element => {
  return (
    <Suspense fallback={<div />}>
      <Route path="/:accountSlug/:brandId?" component={Main} />
    </Suspense>
  );
};
