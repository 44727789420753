import React from 'react';

import { Skeleton } from './skeleton';
interface CardProps {
  title: string;
  count: number;
  handleClick: (name: string) => void;
}

export function Card({ title, count, handleClick }: CardProps) {
  return (
    <button
      type="button"
      className="min-w-[196px] md:min-w-fit box-content flex flex-col gap-3 md:gap-6 p-3 md:p-6 border rounded-xl shadow-sm"
      style={{
        textDecoration: 'none',
        cursor: 'pointer',
        background: 'none',
      }}
      disabled={count === 0}
      onClick={() => handleClick(title)}
    >
      <p className="text-[18px] font-500 leading-6 text-left">
        {title ?? 'Other'}
      </p>
      <p className="font-400 leading-5 text-gray-600">{`${count} ${
        count === 1 ? 'opening' : 'openings'
      }`}</p>
    </button>
  );
}

Card.Skeleton = function CardSkeleton() {
  return (
    <Skeleton className="h-[82px] md:h-[118px] min-w-[196px] md:min-w-fit box-content gap-3 md:gap-6" />
  );
};
