import React from 'react';

export const CaretRight = ({ ...props }) => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.00006 12.5L11.2349 8.26516C11.3813 8.11872 11.3813 7.88128 11.2349 7.73483L7.00006 3.5"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
