import {
  CareerSiteOpeningResponse,
  CareerSiteService,
} from 'api-clients/monolith';
import { AppContext } from 'contexts/app-context';
import { useContext, useEffect, useState } from 'react';

export function useJobs() {
  const {
    accountSlug,
    brandId,
    position,
    location,
    setLocation,
    categoryName,
    experienceLevelName,
    activeFilters,
    placeId,
    jobSearchQuery,
    setIsFetchingJobs,
    isMobile,
    isJobsFromCurrentLocation,
    page,
  } = useContext(AppContext);

  const [currentCity, setCurrentCity] = useState<string | null>(null);
  const [isCurrentLocation, setIsCurrentLocation] = useState(
    isJobsFromCurrentLocation,
  );
  const [jobs, setJobs] = useState<CareerSiteOpeningResponse['openings']>([]);
  const [paginationData, setPaginationData] =
    useState<CareerSiteOpeningResponse['pagination']>();

  useEffect(() => {
    const fetchData = async () => {
      setIsFetchingJobs(true);
      await CareerSiteService.getInternalApiCareerSiteOpenings(
        {
          account_slug: accountSlug,
          brand_id: brandId,
          position_id: position.id ?? undefined,
          location_id: location.id ?? undefined,
          experience_level: experienceLevelName ?? undefined,
          place_id: placeId ?? undefined,
          is_jobs_from_current_location: isJobsFromCurrentLocation,
        },
        activeFilters.radius.key,
        activeFilters.sortBy.key,
        activeFilters.category.key,
        activeFilters.compensationType.key,
        page,
        jobSearchQuery ?? undefined,
        activeFilters.department.label === 'All'
          ? undefined
          : activeFilters.department.label,
        activeFilters.location.key,
        activeFilters.position.key === 'all'
          ? undefined
          : activeFilters.position.key,
      )
        .then((result: CareerSiteOpeningResponse) => {
          if (isMobile && page && page > 1)
            setJobs(prev => [...prev, ...result.openings]);
          else setJobs(result.openings);
          setPaginationData(result.pagination);
          setIsCurrentLocation(result.is_jobs_from_current_location);
          setCurrentCity(result.current_city);
        })
        .catch(() => {
          // TODO: Log error
        })
        .finally(() => {
          setIsFetchingJobs(false);
        });
    };
    void fetchData();
  }, [
    accountSlug,
    activeFilters,
    brandId,
    categoryName,
    experienceLevelName,
    page,
    location,
    position.id,
    placeId,
    jobSearchQuery,
    setIsFetchingJobs,
    setLocation,
    isMobile,
    isJobsFromCurrentLocation,
  ]);

  return {
    jobs,
    paginationData,
    isCurrentLocation,
    currentCity,
  };
}
