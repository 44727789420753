import React from 'react';
import { Helmet } from 'react-helmet';

export interface ChatAgentWidgetProps {
  chatAgentConfig: {
    agentId?: string;
    widgetUiUrl?: string;
    connectionUrl?: string;
  };
}

export function ChatAgentWidget({ chatAgentConfig }: ChatAgentWidgetProps) {
  return (
    <Helmet>
      <script>
        {`
          window.fountainAiConfig = { agentId: '${
            chatAgentConfig.agentId as string
          }', uri: '${chatAgentConfig.widgetUiUrl as string}', server: '${
          chatAgentConfig.connectionUrl as string
        }' };
        `}
      </script>
      <script
        async
        defer
        src={`${chatAgentConfig.widgetUiUrl as string}/ax-agent-widget.js`}
      />
    </Helmet>
  );
}
