/* eslint-disable camelcase */
import { CareerSiteOpening, OpenAPI } from 'api-clients/monolith';
import { AppContext } from 'contexts/app-context';
import { BriefcaseIcon } from 'icons/briefcase';
import { CaretRight } from 'icons/caret-right';
import { CurrencyIcon } from 'icons/currency';
import { LocationMarkerIcon } from 'icons/location-marker';
import React, { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { RouteParams } from 'types';

import { Skeleton } from 'components/ui/skeleton';

interface JobsListProps {
  jobs: CareerSiteOpening[];
}

export function JobsList({ jobs }: JobsListProps) {
  const { accountSlug } = useParams<RouteParams>();
  const { isMobile } = useContext(AppContext);

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const utmSource = params.get('utm_source') ?? undefined;
  const utmCampaign = params.get('utm_campaign') ?? undefined;
  const utmMedium = params.get('utm_medium') ?? undefined;
  const utmTerm = params.get('utm_term') ?? undefined;
  const utmContent = params.get('utm_content') ?? undefined;
  const referralWorkerName = params.get('referralWorkerName') ?? undefined;

  const handleApplyClick = (id: string) => {
    const url = new URL(`${OpenAPI.BASE}/${accountSlug}/apply/${id}`);

    url.searchParams.set('utm_medium', 'ai-career-site');
    if (utmSource) url.searchParams.set('utm_source', utmSource);
    if (utmCampaign) url.searchParams.set('utm_campaign', utmCampaign);
    if (utmMedium) url.searchParams.set('utm_medium', utmMedium);
    if (utmTerm) url.searchParams.set('utm_term', utmTerm);
    if (utmContent) url.searchParams.set('utm_content', utmContent);
    if (referralWorkerName)
      url.searchParams.set('referralWorkerName', referralWorkerName);
    window.open(url, '_blank');
  };

  if (!jobs || jobs.length === 0) {
    return null;
  }

  return (
    <>
      {jobs.map(job => (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <button
          key={job.id}
          className="flex justify-between items-center border border-gray-200 rounded-[12px] md:border-none md:border-b md:border-b-gray-200 shadow-sm p-3 md:px-10 md:py-4 hover:cursor-pointer md:hover:cursor-default"
          type="button"
          onClick={() => isMobile && handleApplyClick(job.id)}
        >
          <div className="flex flex-col gap-2 items-start">
            <h3 className="text-16 font-500 leading-6 whitespace-normal text-start">
              {job.title}
            </h3>
            <div className="flex flex-col md:flex-row gap-[2px] md:gap-4 items-start md:items-center text-gray-600 text-14 leading-5">
              {job.location && (
                <div className="flex flex-row gap-1 items-center">
                  <LocationMarkerIcon className="hidden md:block" />
                  <p>{job.location}</p>
                  {job.distance && (
                    <>
                      <span className="h-1 w-1 rounded-full bg-gray-600"></span>
                      <p>{job.distance}</p>
                    </>
                  )}
                </div>
              )}
              {job.pay_rate && (
                <div className="flex flex-row gap-1 items-center">
                  <CurrencyIcon className="hidden md:block" />
                  <p>{job.pay_rate}</p>
                </div>
              )}
              {job.job_type && (
                <div className="flex flex-row gap-1 items-center">
                  <BriefcaseIcon className="hidden md:block" />
                  <p>{job.job_type}</p>
                </div>
              )}
            </div>
          </div>
          <button
            onClick={() => handleApplyClick(job.id)}
            type="button"
            className="bg-primary text-primary-foreground hover:bg-primary-600 px-6 py-1 text-16 leading-5 font-500 h-[40px] rounded-6 shadow-sm hidden md:flex items-center gap-4"
          >
            Apply
            <CaretRight />
          </button>
        </button>
      ))}
    </>
  );
}

JobsList.Skeleton = function JobsListSkeleton() {
  return (
    <div className="flex justify-between items-center border border-gray-200 rounded-[12px] md:border-none md:border-b md:border-b-gray-200 shadow-sm p-3 md:px-10 md:py-4">
      <div className="flex flex-col gap-2">
        <Skeleton className="h-6 w-[200px]" />
        <div className="flex flex-col md:flex-row gap-[2px] md:gap-4 items-start md:items-center text-gray-600 text-14 leading-5">
          <Skeleton className="h-5 w-[150px]" />
          <Skeleton className="h-5 w-[150px]" />
          <Skeleton className="h-5 w-[150px]" />
        </div>
      </div>
      <Skeleton className="h-10 w-[125px] hidden md:block" />
    </div>
  );
};

JobsList.SkeletonGroup = function JobsListSkeletonGroup() {
  return (
    <>
      {Array.from({ length: 10 }, (_, i) => (
        <JobsList.Skeleton key={`skeleton-${i}`} />
      ))}
    </>
  );
};
