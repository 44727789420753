import cx from 'classnames';
import { AppContext } from 'contexts/app-context';
import { SearchIcon } from 'icons/search-icon';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { SearchPopover } from './search-popover';
interface MasterSearchProps {
  scrollToJobs: () => void;
}

export function MasterSearch({ scrollToJobs }: MasterSearchProps) {
  const listRef = useRef<HTMLDivElement>(null);

  const { setJobSearchQuery, resetFilters, isCmsAccount } =
    useContext(AppContext);

  const [openPopover, setOpenPopover] = useState(false);
  const [searchString, setSearchString] = useState('');

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (listRef.current && !listRef.current.contains(event.target as Node)) {
      setOpenPopover(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (value: string) => {
    setSearchString(value);
  };

  const resetSearch = () => {
    setSearchString('');
  };

  const searchJobs = () => {
    resetFilters();
    setJobSearchQuery(searchString);
    setOpenPopover(false);
    resetSearch();
  };

  const handleJobSearch = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      searchJobs();
    }
  };

  const handleGoClick = () => {
    searchJobs();
  };

  return (
    <div className="relative w-full md:w-[560px]" ref={listRef}>
      <div className="flex flex-row gap-4 items-center">
        <button
          type="button"
          onClick={handleOpenPopover}
          className="w-full h-[48px] flex items-center gap-3 border rounded-[5px] bg-white px-4 py-3"
        >
          <SearchIcon />
          <input
            placeholder="Search by job title or location"
            onChange={e => handleInputChange(e.target.value)}
            onKeyDown={handleJobSearch}
            className="border-none outline-none flex-1"
            value={searchString}
          />
        </button>
        <button
          type="button"
          onClick={handleGoClick}
          className={cx(
            'md:hidden h-[48px] rounded-[48px] text-primary bg-primary-foreground text-16 font-500 leading-5 px-4 py-2',
            { '!bg-primary-500 !text-white': isCmsAccount },
          )}
        >
          Go
        </button>
      </div>
      {openPopover && (
        <SearchPopover
          searchString={searchString}
          setOpenPopover={setOpenPopover}
          scrollToJobs={scrollToJobs}
          resetSearch={resetSearch}
        />
      )}
    </div>
  );
}
