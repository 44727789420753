import React from 'react';

import { Home } from 'components/home';
import { Nav } from 'components/nav';

export const HomeLoading = () => {
  return (
    <>
      <Nav.Skeleton />
      <Home.Skeleton />
    </>
  );
};
