import { OpenAPI } from 'api-clients/monolith';
import { AppContext } from 'contexts/app-context';
import React, { useContext, VFC } from 'react';
import Highlighter from 'react-highlight-words';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'types';

import { useJobsSearch } from 'hooks/useJobsSearch';

import { Skeleton } from './ui/skeleton';

export function SearchPopoverSkeleton() {
  return (
    <div className="absolute mt-1 w-full h-[236px] overflow-y-scroll pt-2 rounded-6 bg-white border border-gray-100 shadow-lg cursor-default">
      <p className="px-4 py-[6px] uppercase bg-gray-200 text-12 font-700 leading-4">
        jobs
      </p>
      {Array.from({ length: 5 }, (_, i) => (
        <div
          key={`skeleton-${i}`}
          className="h-[60px] flex flex-col gap-1 w-full px-4 py-3 text-start"
        >
          <Skeleton className="h-[18px] w-[200px]" />
          <div className="h-4 flex gap-1 items-center">
            <Skeleton className="h-4 w-[150px]" />
            <p className="text-12 text-gray-300">|</p>
            <Skeleton className="h-4 w-[150px]" />
          </div>
        </div>
      ))}
    </div>
  );
}

export interface SearchPopoverProps {
  searchString: string;
  setOpenPopover: React.Dispatch<React.SetStateAction<boolean>>;
  scrollToJobs: () => void;
  resetSearch: () => void;
}

export const SearchPopover: VFC<SearchPopoverProps> = ({
  searchString,
  setOpenPopover,
  scrollToJobs,
  resetSearch,
}) => {
  const {
    setPosition,
    setCategoryName,
    resetFilters,
    setIsJobsFromCurrentLocation,
    setPlaceId,
  } = useContext(AppContext);
  const { accountSlug, brandId } = useParams<RouteParams>();

  const { data, isFetching } = useJobsSearch(
    accountSlug,
    brandId,
    searchString,
  );

  const handleSelect = (type: string, id: string, value: string) => {
    setOpenPopover(false);
    setCategoryName(null);
    resetSearch();

    if (type === 'jobs') {
      window.open(`${OpenAPI.BASE}/${accountSlug}/apply/${id}`, '_blank');
    } else if (type === 'positions') {
      resetFilters();
      setPosition({ id, value });
      scrollToJobs();
    } else if (type === 'locations') {
      resetFilters();
      setIsJobsFromCurrentLocation(true);
      setPlaceId(id);
      scrollToJobs();
    }
  };

  if (isFetching) {
    return <SearchPopoverSkeleton />;
  }

  return (
    <>
      {data && (
        <div className="absolute mt-1 w-full max-h-[236px] overflow-y-scroll pt-2 rounded-6 bg-white border border-gray-100 shadow-lg cursor-default">
          {Object.entries(data).map(([type, values]) => (
            <span key={type}>
              {values && values.length > 0 && (
                <p className="px-4 py-[6px] uppercase bg-gray-200 text-12 font-700 leading-4">
                  {type}
                </p>
              )}
              {values.map(item => (
                <div key={item.external_id}>
                  <button
                    type="button"
                    onClick={() =>
                      handleSelect(type, item.external_id, item.name)
                    }
                    className="flex flex-col gap-1 w-full px-4 py-3 text-start text-14 font-400 leading-4 text-gray-800 hover:bg-primary-50"
                  >
                    <Highlighter
                      autoEscape
                      searchWords={[searchString]}
                      textToHighlight={item.name}
                      highlightClassName="text-primary"
                      highlightStyle={{ background: '#ffda99' }}
                    />
                    {type === 'jobs' && (
                      <p className="text-12 text-gray-500">
                        {item.location} &nbsp;|&nbsp; {item.position}
                      </p>
                    )}
                  </button>
                </div>
              ))}
            </span>
          ))}
        </div>
      )}
    </>
  );
};
