import React from 'react';

import { DesktopNav } from 'components/desktop-nav';

import { ChatAgentWidget } from './chat-agent-widget';

export interface NavProps {
  logoUrl: string;
  showWidgetByDefault: boolean;
  showChatAgentWidget: boolean;
  chatAgentConfig: {
    agentId?: string;
    widgetUiUrl?: string;
    connectionUrl?: string;
  };
}

export function Nav({
  logoUrl,
  showWidgetByDefault,
  showChatAgentWidget,
  chatAgentConfig,
}: NavProps) {
  return (
    <header className="sticky top-0 z-40 border-b bg-white">
      <div className="container flex h-16 justify-center p-2">
        <DesktopNav logoUrl={logoUrl} />
        {showChatAgentWidget && showWidgetByDefault && (
          <ChatAgentWidget chatAgentConfig={chatAgentConfig} />
        )}
      </div>
    </header>
  );
}

Nav.Skeleton = function NavSkeleton() {
  return (
    <div className="container flex h-16 justify-center p-6">
      <DesktopNav.Skeleton />
    </div>
  );
};
