import {
  AMPLITUDE_PRODUCTION_API_KEY,
  AMPLITUDE_STAGING_API_KEY,
} from './amplitude/constants';
import {
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  DATADOG_SAMPLE_RATE,
  DATADOG_SITE,
} from './datadog/constants';

interface Datadog {
  applicationId: string;
  clientToken: string;
  sessionSampleRate: number;
  site: string;
  isEnabled: boolean;
}
interface DomainMeta {
  environment: 'dev' | 'stage' | 'uat' | 'prod';
  monolithOrigin: string;
  tenant: string;
  apiKey: {
    amplitude?: string;
  };
  datadog: Datadog;
}

const getDatadogDetails = (isEnabled: boolean): Datadog => ({
  applicationId: DATADOG_APPLICATION_ID,
  clientToken: DATADOG_CLIENT_TOKEN,
  sessionSampleRate: DATADOG_SAMPLE_RATE,
  site: DATADOG_SITE,
  isEnabled,
});

// Searches for any domain such as careers.ap-1.fountain.com. Will not match `careers.fountain.com`.
const FOUNTAIN_DOMAIN_MATCHER = /^careers\.[^.]*\.fountain\.com/;
// Matches the staging domains such as careers.staging-use-05.fountain.com
const FOUNTAIN_STAGING_DOMAIN_MATCHER = /^careers\.staging[^.]*\.fountain\.com/;

const FOUNTAIN_DEV_DOMAINS = [
  'careers.dev-01.fountain.com',
  'careers.dev-02.fountain.com',
  'careers.faut-02.fountain.com',
];

const FOUNTAIN_UAT_DOMAINS = [
  'careers.uat-01.fountain.com',
  'careers.faut-01.fountain.com',
];

const isStagingDomain = (hostname: string) => {
  if (FOUNTAIN_STAGING_DOMAIN_MATCHER.test(hostname)) {
    return true;
  }
  return false;
};

const isUatDomain = (hostname: string) => {
  if (FOUNTAIN_UAT_DOMAINS.includes(hostname)) {
    return true;
  }
  return false;
};

const isDevDomain = (hostname: string) => {
  if (FOUNTAIN_DEV_DOMAINS.includes(hostname)) {
    return true;
  }
  return false;
};

export const inferDomainMeta = (hostname: string): DomainMeta => {
  if (hostname === 'localhost') {
    return {
      environment: 'dev',
      monolithOrigin: `http://localhost:${
        process.env.NODE_ENV === 'test' ? 3023 : 3000
      }`,
      tenant: 'local',
      apiKey: {
        amplitude: AMPLITUDE_STAGING_API_KEY,
      },
      datadog: getDatadogDetails(false),
    };
  }

  if (hostname.endsWith('.chromatic.com')) {
    return {
      environment: 'dev',
      monolithOrigin: 'http://localhost:3000',
      tenant: 'storybook',
      apiKey: {},
      datadog: getDatadogDetails(false),
    };
  }

  if (isDevDomain(hostname)) {
    return {
      environment: 'dev',
      monolithOrigin: `https://${hostname.slice(8)}`,
      tenant: hostname.split('.')[1],
      apiKey: {},
      datadog: getDatadogDetails(false),
    };
  }

  if (isStagingDomain(hostname)) {
    return {
      environment: 'stage',
      monolithOrigin: `https://${hostname.slice(8)}`,
      tenant: hostname.split('.')[1],
      apiKey: {
        amplitude: AMPLITUDE_STAGING_API_KEY,
      },
      datadog: getDatadogDetails(true),
    };
  }

  if (isUatDomain(hostname)) {
    return {
      environment: 'uat',
      monolithOrigin: `https://${hostname.slice(8)}`,
      tenant: hostname.split('.')[1],
      apiKey: {
        amplitude: AMPLITUDE_STAGING_API_KEY,
      },
      datadog: getDatadogDetails(true),
    };
  }

  if (hostname === 'careers.fountain.com') {
    return {
      environment: 'prod',
      monolithOrigin: 'https://web.fountain.com',
      tenant: 'fountain',
      apiKey: {
        amplitude: AMPLITUDE_PRODUCTION_API_KEY,
      },
      datadog: getDatadogDetails(true),
    };
  }

  if (FOUNTAIN_DOMAIN_MATCHER.test(hostname)) {
    return {
      environment: 'prod',
      monolithOrigin: `https://${hostname.slice(8)}`,
      tenant: hostname.split('.')[1],
      apiKey: {
        amplitude: AMPLITUDE_PRODUCTION_API_KEY,
      },
      datadog: getDatadogDetails(true),
    };
  }

  throw new Error('Unable to map hostname to a known domain');
};

export const domainMeta = inferDomainMeta(window.location.hostname);
