import { datadogLogs, LogsEvent } from '@datadog/browser-logs';
import { datadogRum, RumInitConfiguration } from '@datadog/browser-rum';

export const beforeSend = (log: LogsEvent): void | boolean => {
  /* eslint-disable consistent-return */
  if (!log.http || log.http.status_code !== 0) return;
  if (!log.error || log.error.origin !== 'network') return;
  // [OPT-986] Unactionable network errors for Pusher
  if (!log.http.url || !log.http.url.match('pusher.com')) return;

  return false;
  /* eslint-enable consistent-return */
};

export const initializeDatadog = ({
  allowedTracingOrigins,
  applicationId,
  clientToken,
  env,
  sessionSampleRate,
  service,
  site,
  tenant,
  version,
}: {
  allowedTracingOrigins?: RumInitConfiguration['allowedTracingOrigins'];
  applicationId: string;
  clientToken: string;
  env: string;
  sessionSampleRate?: number;
  service: string;
  site?: string;
  tenant: string;
  version?: string;
}) => {
  datadogLogs.init({
    beforeSend,
    clientToken,
    env,
    forwardErrorsToLogs: true,
    // CSP violations are not forwarded to Datadog and instead reported to
    // Cloudflare due to their Page Shield requiring a `script-src 'none'`
    // policy which is incredibly noisy within Datadog.
    //
    // N.B. May need revisited when an `career-site-ui`-specific CSP is set!
    forwardReports: ['deprecation', 'intervention'],
    sessionSampleRate,
    service,
    site,
    version,
  });

  datadogRum.init({
    allowedTracingOrigins,
    applicationId,
    clientToken,
    defaultPrivacyLevel: 'mask',
    env,
    sessionSampleRate,
    sessionReplaySampleRate: 0,
    service,
    site,
    version,
  });

  [datadogLogs, datadogRum].forEach(datadogSdk =>
    datadogSdk.setGlobalContextProperty('ftn', { tenant }),
  );
};
