import { datadogRum } from '@datadog/browser-rum';
import {
  CareerSiteBrandConfigurationResponse,
  CareerSiteService,
} from 'api-clients/monolith';
import { useEffect, useState } from 'react';

export function useBrandConfig(accountSlug: string, brandId: string) {
  const [data, setData] = useState<CareerSiteBrandConfigurationResponse>();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const fetchData = () => {
      setIsFetching(true);
      CareerSiteService.getInternalApiCareerSiteBrandsConfiguration({
        account_slug: accountSlug,
        brand_id: brandId,
      })
        .then((result: CareerSiteBrandConfigurationResponse) => {
          setData(result);
        })
        .catch(err => {
          datadogRum.addError(err);
        })
        .finally(() => {
          setIsFetching(false);
        });
    };
    void fetchData();
  }, [accountSlug, brandId]);

  return { data, isFetching, setData };
}
