import { datadogRum } from '@datadog/browser-rum';
import {
  CareerSitePositionExpLevelsResponse,
  CareerSiteService,
} from 'api-clients/monolith';
import { useEffect, useState } from 'react';

export function useExperienceLevels(accountSlug: string, brandId: string) {
  const [data, setData] = useState<
    CareerSitePositionExpLevelsResponse['experience_levels']
  >([]);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      await CareerSiteService.getInternalApiCareerSitePositionsExperienceLevels(
        {
          account_slug: accountSlug,
          brand_id: brandId,
        },
      )
        .then((result: CareerSitePositionExpLevelsResponse) => {
          setData(result.experience_levels);
        })
        .catch(err => {
          datadogRum.addError(err);
        })
        .finally(() => {
          setIsFetching(false);
        });
    };
    void fetchData();
  }, [accountSlug, brandId]);

  return { data, isFetching };
}
