import { AppContext } from 'contexts/app-context';
import React, { FocusEvent, useContext, useMemo } from 'react';

import { JobsList } from './jobs-list';
import { Skeleton } from './ui/skeleton';

export function PaginationSkeleton() {
  return (
    <div className="flex items-center justify-center mt-3">
      <Skeleton className="h-6 w-[200px]" />
    </div>
  );
}

export interface PaginationProps {
  currentPage: number;
  lastPage: number;
  setPage: (value: number) => void;
  jobsCount: number;
}

export function Pagination({
  currentPage,
  lastPage,
  setPage,
  jobsCount,
}: PaginationProps) {
  const { isMobile, isFetchingJobs } = useContext(AppContext);

  const disableNextPage = useMemo(
    () => currentPage === lastPage || !currentPage || lastPage === 0,
    [currentPage, lastPage],
  );

  const setDefaultPage = (e: FocusEvent<HTMLInputElement>) => {
    if (Number(e.target.value) > 0 && Number(e.target.value) <= lastPage) {
      setPage(Number(e.target.value));
    } else {
      setPage(1);
      // TODO: Show error toast
    }
  };

  if (isMobile && isFetchingJobs && currentPage > 1) {
    return (
      <>
        <JobsList.Skeleton />
        <PaginationSkeleton />
      </>
    );
  }

  return (
    <div
      className={`flex items-center justify-center gap-2 text-14 font-400 leading-4 mt-3 ${
        disableNextPage && isMobile ? 'hidden' : ''
      }`}
    >
      {isMobile ? (
        <button
          type="button"
          onClick={() => setPage(currentPage + 1)}
          className="font-[450] underline text-blue-400 hover:text-blue-600"
        >
          {`See more (${jobsCount - currentPage * 10})`}
        </button>
      ) : (
        <>
          <button
            type="button"
            onClick={() => setPage(currentPage - 1)}
            disabled={currentPage === 1 || !currentPage}
            className="bg-white disabled:text-gray-400"
          >
            Prev
          </button>
          <p>Page</p>
          <input
            onKeyDown={e =>
              e.keyCode === 13 && (e.target as HTMLElement).blur()
            }
            defaultValue={currentPage}
            onBlur={setDefaultPage}
            className="w-9 h-6 border border-inherit rounded text-center"
          />
          <p>of {lastPage}</p>
          <button
            type="button"
            onClick={() => setPage(currentPage + 1)}
            disabled={disableNextPage}
            className="bg-white disabled:text-gray-400"
          >
            Next
          </button>
        </>
      )}
    </div>
  );
}
