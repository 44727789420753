import { CareerSiteSettings } from 'api-clients/monolith';
import cx from 'classnames';
import { AppContext } from 'contexts/app-context';
import { ChatIcon } from 'icons/chat-icon';
import React, { useContext } from 'react';

import { Skeleton } from 'components/ui/skeleton';

import { MasterSearch } from './master-search';

interface FaiWidget {
  dispatchMessage: (payload: {
    name: string;
    args?: { message?: string };
  }) => void;
}

declare global {
  interface Window {
    faiWidget?: FaiWidget;
  }
}

interface HomeSearchProps {
  scrollToJobs: () => void;
  brandSetting: CareerSiteSettings;
}
export function HomeSearch({ scrollToJobs, brandSetting }: HomeSearchProps) {
  const { brandName, isCmsAccount } = useContext(AppContext);

  const postMessageToWidget = (message?: string) => {
    if (!window || !window.faiWidget) {
      return;
    }

    window.faiWidget.dispatchMessage({
      name: 'x-fai-window-maximize',
    });

    if (message) {
      window.faiWidget?.dispatchMessage({
        name: 'x-fai-window-post-message',
        args: { message },
      });
    }
  };

  const handleTagClick = (content: string) => {
    postMessageToWidget(content);
  };

  return (
    <div
      className={cx(
        'h-full flex flex-col justify-center items-center gap-y-6 py-4 px-4',
        {
          'bg-gradient-to-br from-30% via-primary-400 via-70% to-primary-500 to-100% from-primary-500 py-10':
            !isCmsAccount,
        },
      )}
    >
      <h1
        className={cx('text-3xl font-medium text-center', {
          'text-primary-foreground mb-4': !isCmsAccount,
        })}
      >
        Search jobs at <span className="whitespace-nowrap">{brandName}</span>
      </h1>

      <MasterSearch scrollToJobs={scrollToJobs} />

      <div className="flex flex-wrap justify-center items-center gap-2 lg:w-[50%] md:w-[70%]">
        {brandSetting?.show_chat_agent_widget &&
          brandSetting?.show_faq_chatbot_by_default && (
            <>
              {brandSetting?.prefilled_faq_chatbot_questions?.map(message => (
                <button
                  key={message}
                  type="button"
                  onClick={() => handleTagClick(message)}
                  className={cx(
                    'h-[32px] px-3 py-2 rounded-2xl text-[13px] leading-4',
                    { 'bg-primary-50': !isCmsAccount },
                  )}
                >
                  {message}
                </button>
              ))}
              <button
                type="button"
                onClick={() => postMessageToWidget()}
                className={cx(
                  'flex items-center gap-1 bg-primary-foreground px-4 py-2 h-[40px] rounded-[40px] text-14 font-500 leading-5',
                  { 'text-primary': !isCmsAccount },
                )}
              >
                <ChatIcon />
                <p>Chat with us</p>
              </button>
            </>
          )}
      </div>
    </div>
  );
}

HomeSearch.Skeleton = function HomeSearchSkeleton() {
  return <Skeleton className="w-full h-full" />;
};
