import React from 'react';

export const ArrowDownIcon = ({ ...props }) => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.66653 2H0.333198C0.272491 2.00002 0.212942 2.01109 0.16098 2.03201C0.109017 2.05294 0.0666131 2.08293 0.0383443 2.11875C0.0100756 2.15456 -0.00298468 2.19485 0.000572972 2.23525C0.00413063 2.27566 0.0241711 2.31465 0.0585317 2.34801L3.7252 5.90368C3.75583 5.93338 3.79684 5.95767 3.84469 5.97445C3.89254 5.99123 3.9458 6 3.99987 6C4.05393 6 4.10719 5.99123 4.15504 5.97445C4.20289 5.95767 4.2439 5.93338 4.27453 5.90368L7.9412 2.34801C7.97556 2.31465 7.9956 2.27566 7.99916 2.23525C8.00272 2.19485 7.98965 2.15456 7.96139 2.11875C7.93312 2.08293 7.89071 2.05294 7.83875 2.03201C7.78679 2.01109 7.72724 2.00002 7.66653 2Z"
      fill="#666F89"
    />
  </svg>
);
