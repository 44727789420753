import './styles.css';

import { CareerSitePositionCategory } from 'api-clients/monolith';
import React from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

import { Card } from '../card';
import { LeftArrow, RightArrow } from './arrows';

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

interface HorizontalScrollProps {
  categories: CareerSitePositionCategory[];
  handleClick: (name: string) => void;
}

export function HorizontalScroll({
  categories: items,
  handleClick,
}: HorizontalScrollProps) {
  const [position, setPosition] = React.useState(0);

  const restorePosition = React.useCallback(
    ({ scrollContainer }: scrollVisibilityApiType) => {
      if (scrollContainer.current) {
        // eslint-disable-next-line no-param-reassign
        scrollContainer.current.scrollLeft = position;
      }
    },
    [position],
  );

  const savePosition = React.useCallback(
    ({ scrollContainer }: scrollVisibilityApiType) =>
      !!scrollContainer.current &&
      setPosition(scrollContainer.current.scrollLeft),
    [],
  );

  const onWheel = (apiObj: scrollVisibilityApiType, ev: React.WheelEvent) => {
    const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

    if (isThouchpad) {
      ev.stopPropagation();
      return;
    }

    if (ev.deltaY < 0) {
      apiObj.scrollNext();
    } else if (ev.deltaY > 0) {
      apiObj.scrollPrev();
    }
  };

  return (
    <>
      <div>
        <ScrollMenu
          LeftArrow={LeftArrow}
          RightArrow={RightArrow}
          onInit={restorePosition}
          onScroll={savePosition}
          onWheel={onWheel}
        >
          {items.map(category => (
            <Card
              title={category.name}
              key={category.name}
              count={category.count}
              handleClick={handleClick}
            />
          ))}
        </ScrollMenu>
      </div>
    </>
  );
}
