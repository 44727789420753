import { useEffect, useState } from 'react';

export const useWindowResize = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    function updateDevice() {
      setIsMobile(window.innerWidth <= 768);
    }

    window.addEventListener('resize', updateDevice);

    return () => window.removeEventListener('resize', updateDevice);
  }, []);

  return { isMobile };
};
