import { AppContext } from 'contexts/app-context';
import React, { useContext } from 'react';

import { useExperienceLevels } from 'hooks/useExperienceLevels';

import { Card } from './ui/card';
import { HorizontalScroll } from './ui/horizontal-scroll';

export function JobExperienceLevelCardsSkeleton() {
  return (
    <div className="flex flex-row overflow-x-scroll no-scrollbar md:overflow-x-hidden md:grid md:grid-cols-3 gap-3">
      {Array.from({ length: 3 }, (_, i) => (
        <Card.Skeleton key={`skeleton-${i}`} />
      ))}
    </div>
  );
}

interface ExperienceLevelCardsProps {
  scrollToJobs: () => void;
}

export function ExperienceLevelCards({
  scrollToJobs,
}: ExperienceLevelCardsProps) {
  const {
    accountSlug,
    brandId,
    setExperienceLevelName,
    resetFilters,
    isMobile,
  } = useContext(AppContext);

  const { data: experienceLevels, isFetching: isExperienceFetching } =
    useExperienceLevels(accountSlug, brandId);

  if (isExperienceFetching) {
    return <JobExperienceLevelCardsSkeleton />;
  }

  const handleClick = (name: string) => {
    scrollToJobs();
    resetFilters();
    setExperienceLevelName(name);
  };

  return (
    <>
      {experienceLevels.length > 0 && (
        <>
          {isMobile ? (
            <div className="relative">
              <HorizontalScroll
                categories={experienceLevels}
                handleClick={handleClick}
              />
            </div>
          ) : (
            <div className="flex flex-row overflow-x-scroll no-scrollbar md:overflow-x-hidden md:grid md:grid-cols-3 gap-3">
              {experienceLevels.map(experienceLevel => (
                <Card
                  title={experienceLevel.name}
                  key={experienceLevel.name}
                  count={experienceLevel.count}
                  handleClick={handleClick}
                />
              ))}
            </div>
          )}
        </>
      )}
    </>
  );
}
