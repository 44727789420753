/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CareerSiteSettingResponse } from '../models/CareerSiteSettingResponse';
import type { UpdateCareerSiteSettingParams } from '../models/UpdateCareerSiteSettingParams';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CareerSiteService {

    /**
     * brand configuration
     * @param careerSite Career Site Params
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiCareerSiteBrandsConfiguration(
        careerSite: {
            account_slug: string;
            brand_id: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/career_site/brands/configuration',
            query: {
                'career_site': careerSite,
            },
        });
    }

    /**
     * list locations
     * @param careerSite Career Site Params
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiCareerSiteLocations(
        careerSite: {
            account_slug: string;
            brand_id: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/career_site/locations',
            query: {
                'career_site': careerSite,
            },
        });
    }

    /**
     * list openings
     * @param careerSite Career Site Params
     * @param radius Distance Radius
     * @param sortBy Sorting param
     * @param category Category Name
     * @param compensationType Compensation Type
     * @param page Pagination defaults to 0
     * @param query Search string
     * @param department Search Depaartment
     * @param location Search Location
     * @param position Search Position
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiCareerSiteOpenings(
        careerSite: {
            account_slug: string;
            brand_id: string;
            position_id?: string;
            location_id?: string;
            experience_level?: string;
            place_id?: string;
            is_jobs_from_current_location?: boolean;
        },
        radius: string,
        sortBy: string,
        category: string,
        compensationType: string,
        page?: number,
        query?: string,
        department?: string,
        location?: string,
        position?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/career_site/openings',
            query: {
                'career_site': careerSite,
                'page': page,
                'radius': radius,
                'sort_by': sortBy,
                'category': category,
                'compensation_type': compensationType,
                'query': query,
                'department': department,
                'location': location,
                'position': position,
            },
        });
    }

    /**
     * list positions
     * @param careerSite Career Site Params
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiCareerSitePositions(
        careerSite: {
            account_slug: string;
            brand_id: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/career_site/positions',
            query: {
                'career_site': careerSite,
            },
        });
    }

    /**
     * list positions categories
     * @param careerSite Career Site Params
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiCareerSitePositionsCategories(
        careerSite: {
            account_slug: string;
            brand_id: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/career_site/positions/categories',
            query: {
                'career_site': careerSite,
            },
        });
    }

    /**
     * list positions experience levels
     * @param careerSite Career Site Params
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiCareerSitePositionsExperienceLevels(
        careerSite: {
            account_slug: string;
            brand_id: string;
        },
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/career_site/positions/experience_levels',
            query: {
                'career_site': careerSite,
            },
        });
    }

    /**
     * list funnel by location
     * @param careerSite Career Site Params
     * @param query search query
     * @returns any successful
     * @throws ApiError
     */
    public static getInternalApiCareerSiteSearchFunnelsByLocation(
        careerSite: {
            account_slug: string;
            brand_id: string;
        },
        query?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/career_site/search/funnels_by_location',
            query: {
                'career_site': careerSite,
                'query': query,
            },
        });
    }

    /**
     * Returns career_site setting details
     * @param brandId Brand Id
     * @returns CareerSiteSettingResponse successful
     * @throws ApiError
     */
    public static getInternalApiCareerSiteSettings(
        brandId: string,
    ): CancelablePromise<CareerSiteSettingResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/internal_api/career_site/settings',
            query: {
                'brand_id': brandId,
            },
        });
    }

    /**
     * Updates career_site settings
     * @param brandId Brand external_id
     * @param requestBody Updates career_site settings
     * @returns CareerSiteSettingResponse successful
     * @throws ApiError
     */
    public static putInternalApiCareerSiteSettings(
        brandId: string,
        requestBody: UpdateCareerSiteSettingParams,
    ): CancelablePromise<CareerSiteSettingResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/internal_api/career_site/settings/{brand_id}',
            path: {
                'brand_id': brandId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}