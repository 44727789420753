import { datadogRum } from '@datadog/browser-rum';
import { CareerSiteService, SearchJobResponse } from 'api-clients/monolith';
import { useEffect, useState } from 'react';

export function useJobsSearch(
  accountSlug: string,
  brandId: string,
  searchString: string,
) {
  const [data, setData] = useState<SearchJobResponse>();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    const fetchData = async () => {
      await CareerSiteService.getInternalApiCareerSiteSearchFunnelsByLocation(
        {
          account_slug: accountSlug,
          brand_id: brandId,
        },
        searchString,
      )
        .then((result: SearchJobResponse) => {
          setData(result);
        })
        .catch(err => {
          datadogRum.addError(err);
        })
        .finally(() => {
          setIsFetching(false);
        });
    };
    const timerId = setTimeout(() => {
      void fetchData();
    }, 300);

    return () => {
      clearTimeout(timerId);
    };
  }, [accountSlug, brandId, searchString]);

  return { data, isFetching };
}
