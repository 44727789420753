import { AppContext } from 'contexts/app-context';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RouteParams } from 'types';

import { Home } from 'components/home';
import { HomeLoading } from 'components/home-loading';
import { Nav } from 'components/nav';
import { useBrandConfig } from 'hooks/useBrandConfig';

export const Main = () => {
  const { accountSlug, brandId } = useParams<RouteParams>();
  const { setBrandDetails } = useContext(AppContext);

  const { data: brandConfig, isFetching: isFetchingBrandConfig } =
    useBrandConfig(accountSlug, brandId);

  useEffect(() => {
    const palette = brandConfig?.pallete_colors?.palette;

    if (palette) {
      const rootElem = document.querySelector(':root') as HTMLHeadElement;
      rootElem.style.setProperty('--primary-50', palette['50'] as string);
      rootElem.style.setProperty('--primary-100', palette['100'] as string);
      rootElem.style.setProperty('--primary-200', palette['200'] as string);
      rootElem.style.setProperty('--primary-300', palette['300'] as string);
      rootElem.style.setProperty('--primary-400', palette['400'] as string);
      rootElem.style.setProperty('--primary-500', palette['500'] as string);
      rootElem.style.setProperty('--primary-600', palette['600'] as string);
      rootElem.style.setProperty('--primary-700', palette['700'] as string);
      rootElem.style.setProperty('--primary-800', palette['800'] as string);
      rootElem.style.setProperty('--primary-900', palette['900'] as string);
      rootElem.style.setProperty('--primary-950', palette['950'] as string);
    }
  }, [brandConfig?.pallete_colors.palette]);

  useEffect(() => {
    setBrandDetails(accountSlug, brandId, brandConfig?.name ?? '');
  }, [brandConfig, accountSlug, brandId, setBrandDetails]);

  if (isFetchingBrandConfig) return <HomeLoading />;

  if (!brandConfig)
    return <div className="flex justify-center"> No Brand Found </div>;

  return (
    <>
      <Nav
        logoUrl={brandConfig.logo_url}
        showWidgetByDefault={brandConfig?.settings?.show_faq_chatbot_by_default}
        showChatAgentWidget={brandConfig?.settings?.show_chat_agent_widget}
        chatAgentConfig={brandConfig?.settings?.chat_agent_config}
      />
      {brandConfig && (
        <Home
          brandSetting={brandConfig.settings}
          radiusOptions={brandConfig.radius_options}
          categoryOptions={brandConfig.category_options}
          departmentOptions={brandConfig.departments}
          locationOptions={brandConfig.locations}
          positionOptions={brandConfig.positions}
        />
      )}
    </>
  );
};
