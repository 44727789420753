import React from 'react';

export const CurrencyIcon = ({ ...props }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5509 8.12489C13.3051 7.67239 14.3021 7.41504 15.3662 7.41504C16.4303 7.41504 17.4273 7.67239 18.1814 8.12489C18.9218 8.56909 19.5369 9.27957 19.5369 10.1955V16.7296C19.5369 17.6059 18.7857 18.1709 18.1109 18.4769C17.3727 18.8116 16.4016 19.0004 15.3662 19.0004C14.3307 19.0004 13.3597 18.8116 12.6215 18.4769C11.9466 18.1709 11.1954 17.6059 11.1954 16.7296V10.1955C11.1954 9.27957 11.8106 8.56909 12.5509 8.12489ZM13.2662 9.31701C12.7486 9.62757 12.5857 9.95976 12.5857 10.1955C12.5857 10.4313 12.7486 10.7635 13.2662 11.074C13.7699 11.3763 14.5107 11.5858 15.3662 11.5858C16.2216 11.5858 16.9624 11.3763 17.4662 11.074C17.9837 10.7635 18.1467 10.4313 18.1467 10.1955C18.1467 9.95976 17.9837 9.62757 17.4662 9.31701C16.9624 9.01476 16.2216 8.80528 15.3662 8.80528C14.5107 8.80528 13.7699 9.01476 13.2662 9.31701ZM12.5857 12.2868V13.7174C12.5857 13.7198 12.5857 13.7289 12.5948 13.749C12.6047 13.771 12.6265 13.8078 12.672 13.8566C12.7664 13.9578 12.935 14.0804 13.1955 14.1985C13.7152 14.4341 14.4819 14.598 15.3662 14.598C16.2504 14.598 17.0172 14.4341 17.5368 14.1985C17.7974 14.0804 17.9659 13.9578 18.0604 13.8566C18.1059 13.8078 18.1277 13.771 18.1376 13.749C18.1466 13.7289 18.1467 13.7198 18.1467 13.7174V12.2868C17.3967 12.7265 16.414 12.976 15.3662 12.976C14.3184 12.976 13.3356 12.7265 12.5857 12.2868ZM12.5857 15.4483V16.7296C12.5857 16.732 12.5857 16.7411 12.5948 16.7612C12.6047 16.7832 12.6265 16.82 12.672 16.8688C12.7664 16.97 12.935 17.0926 13.1955 17.2107C13.7152 17.4463 14.4819 17.6102 15.3662 17.6102C16.2504 17.6102 17.0172 17.4463 17.5368 17.2107C17.7974 17.0926 17.9659 16.97 18.0604 16.8688C18.1059 16.82 18.1277 16.7832 18.1376 16.7612C18.1466 16.7411 18.1467 16.732 18.1467 16.7296V15.4483C18.1347 15.4538 18.1228 15.4593 18.1109 15.4647C17.3727 15.7994 16.4016 15.9882 15.3662 15.9882C14.3307 15.9882 13.3597 15.7994 12.6215 15.4647C12.6095 15.4593 12.5976 15.4538 12.5857 15.4483Z"
      fill="#344063"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 0.5C1 0.223858 1.22386 0 1.5 0H18.1098C18.3859 0 18.6098 0.223858 18.6098 0.5V5.06098C18.6098 5.33712 18.3859 5.56098 18.1098 5.56098H17.7195C17.4434 5.56098 17.2195 5.33712 17.2195 5.06098V1.89024C17.2195 1.6141 16.9957 1.39024 16.7195 1.39024H2.89024C2.6141 1.39024 2.39024 1.6141 2.39024 1.89024V9.23171C2.39024 9.50785 2.6141 9.73171 2.89024 9.73171H7.91463C8.19078 9.73171 8.41463 9.95557 8.41463 10.2317V10.622C8.41463 10.8981 8.19078 11.122 7.91463 11.122H1.69533H1.5C1.22386 11.122 1 10.8981 1 10.622V0.5Z"
      fill="#344063"
    />
    <path
      d="M9.80488 6.95122C10.5727 6.95122 11.1951 6.32879 11.1951 5.56098C11.1951 4.79317 10.5727 4.17073 9.80488 4.17073C9.03707 4.17073 8.41463 4.79317 8.41463 5.56098C8.41463 6.32879 9.03707 6.95122 9.80488 6.95122Z"
      fill="#344063"
    />
  </svg>
);
