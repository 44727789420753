import React from 'react';

import { Skeleton } from 'components/ui/skeleton';

export function Banner() {
  return (
    <div className="bg-banner-mobile md:bg-banner-desktop bg-cover bg-no-repeat bg-center w-full h-[320px] md:h-[480px] xl:h-[600px]" />
  );
}

Banner.Skeleton = function BannerSkeleton() {
  return <Skeleton className="w-full h-[320px] md:h-[480px] xl:h-[600px]" />;
};
