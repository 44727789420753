import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ArrowDownIcon } from 'icons/arrow-down';
import React from 'react';

import { Skeleton } from './skeleton';

export interface DropdownProps {
  label: string;
  type: string;
  value: string;
  options: ReadonlyArray<{ key: string; label: string }>;
  onSelect: (type: string, option: { key: string; label: string }) => void;
}

export function Dropdown({
  label,
  value,
  options,
  type,
  onSelect,
}: DropdownProps) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger className="flex items-center gap-2 h-8 w-full md:w-fit px-3 py-2 rounded-3xl bg-gray-100 outline-none">
        <p className="text-14 leading-4">
          {label}: <b>{value}</b>
        </p>
        <ArrowDownIcon />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          sideOffset={5}
          className="z-1000 min-w-[240px] py-2 rounded-6 bg-white border border-solid border-gray-100 shadow-md cursor-default overflow-auto max-h-[500px]"
        >
          {options.map(option => (
            <DropdownMenu.Item
              key={option.key}
              className="text-14 leading-4 px-4 py-3 outline-none focus:bg-primary-50 select-all"
              onSelect={() => onSelect(type, option)}
            >
              {option.label}
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

Dropdown.Skeleton = function DropdownSkeleton() {
  return <Skeleton className="w-full md:w-[150px] h-[32px] !rounded-3xl" />;
};
